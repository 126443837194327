<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :footer-actions="footerActions"
      :sums="sums"
      :is-page-show="true"
      api-url="/goods-production-list"
      api-download-url="/goods-production-export"
      download-file-name="sx-may-dinh-luong.xlsx"
      @showDialogCreate="showDialogCreate"
      @showDialogClone="showDialogClone"
      @showDialogDetail="showDialogDetail"
    ></custom-table>

    <v-dialog v-model="createDialog" max-width="1024px">
      <RecordCreate
        v-if="createDialog"
        @refreshData="refreshCustomTable"
        @close="hideDialogCreate"
      />
    </v-dialog>

    <v-dialog v-model="cloneDialog" max-width="1024px">
      <RecordClone
        v-if="cloneDialog"
        :item="updatingItem"
        @refreshData="refreshCustomTable"
        @close="hideDialogClone"
      />
    </v-dialog>

    <v-dialog v-model="detailDialog" max-width="1366px">
      <RecordDetail
        v-if="detailDialog"
        :item="updatingItem"
        @refreshData="refreshCustomTable"
        @close="hideDialogDetail"
      />
    </v-dialog>
  </div>
</template>

<script>
import { IMAGE_OPTIONS, YES_NO_OPTIONS } from "@/libs/const";

export default {
  name: "Record",
  components: {
    RecordCreate: () => import("@/components/goods_production/RecordCreate"),
    RecordDetail: () => import("@/components/goods_production/RecordDetail"),
    RecordClone: () => import("@/components/goods_production/RecordClone"),
  },
  data: () => ({
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    imageOptions: [...IMAGE_OPTIONS],
    statusOptions: [...YES_NO_OPTIONS],
    createDialog: false,
    detailDialog: false,
    cloneDialog: false,
    updatingItem: {},
  }),
  computed: {},
  created() {
    const columns = [
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_id",
        hasSort: false,
        sortName: "tracking_id",
        key: "tracking_id",
        required: true,
        action: "showDialogDetail",
        actionPermissions: [],
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.created_at"),
        placeholder: this.$t("labels.created_at"),
        name: "created_at",
        hasSort: false,
        sortName: "created_at",
        key: "created_at",
        required: true,
      },
      {
        type: "select-employee",
        label: this.$t("labels.employee_create"),
        placeholder: this.$t("labels.employee_create"),
        name: "id_identity",
        hasSort: false,
        sortName: "identity_name",
        key: "identity_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.sku"),
        placeholder: this.$t("labels.sku"),
        name: "sku",
        hasSort: false,
        sortName: "sku",
        key: "sku",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.barcode"),
        placeholder: this.$t("labels.barcode"),
        name: "customer_goods_barcode",
        hasSort: false,
        sortName: "customer_goods_barcode",
        key: "customer_goods_barcode",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.image"),
        placeholder: this.$t("labels.image"),
        name: "image",
        hasSort: false,
        sortName: "url_images",
        key: "url_images",
        options: this.imageOptions,
        required: true,
        component: "Image",
      },
      {
        type: "input-filter",
        label: this.$t("labels.name"),
        placeholder: this.$t("labels.name"),
        name: "name",
        hasSort: false,
        sortName: "name",
        key: "name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.goods_description"),
        placeholder: this.$t("labels.goods_description"),
        name: "description",
        hasSort: false,
        sortName: "description",
        key: "description",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.size"),
        placeholder: this.$t("labels.size"),
        name: "size",
        hasSort: false,
        sortName: "size",
        key: "size",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.size"),
        placeholder: this.$t("labels.size"),
        name: "size",
        hasSort: false,
        sortName: "size",
        key: "size",
      },
      {
        type: "select-filter",
        label: this.$t("labels.status"),
        placeholder: this.$t("labels.status"),
        name: "status",
        hasSort: false,
        sortName: "status",
        key: "status",
        options: this.statusOptions,
        required: true,
      },
      {
        type: "button",
        label: this.$t("labels.note"),
        headerAction: "showDialogCreate",
        headerActionColor: "primary",
        headerActionLabel: this.$t("labels.add"),
        action: false,
        required: true,
        buttonActions: [
          {
            action: "showDialogClone",
            text: this.$t("labels.clone"),
            color: "warning",
            permissions: [],
            allowKey: "can_clone",
          },
        ],
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },
    showDialogCreate() {
      this.createDialog = true;
    },
    hideDialogCreate() {
      this.createDialog = false;
    },
    showDialogDetail(item) {
      this.updatingItem = { ...item };
      this.detailDialog = true;
    },
    hideDialogDetail() {
      this.updatingItem = {};
      this.detailDialog = false;
    },
    showDialogClone(item) {
      this.updatingItem = { ...item };
      this.cloneDialog = true;
    },
    hideDialogClone() {
      this.updatingItem = {};
      this.cloneDialog = false;
    },
  },
};
</script>

<style scoped></style>
